<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
    <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
    <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

    </v-breadcrumbs>
  </div></v-row><br><br>
    <br>
    <v-dialog persistent v-model="isLoading">
      <v-row justify="center">
        <v-card width="400">
          <center class="pa-5">
            Importing Book data.<br />
            Please Wait It will Take Time..

          </center>
          <div class="ma-6">
            <v-progress-linear v-model="percent" color="amber" height="25">
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </div>
        </v-card>
      </v-row>
    </v-dialog>


    <v-card class="card">
      <v-card-title class="heading justify-center">
        <span>Title</span>
      </v-card-title>
      
    
      <v-card-text>
        <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
        <v-row justify="center" align="center">
          <v-col cols="12" sm=2 md=2></v-col>
          <v-col cols="12" sm=2 md=3>
            <v-form ref="form"><br>
              <label class="add-text">Add New Title</label><span style="color: red;"> *</span>
              <v-text-field class="text" v-model="title" outlined dense autocomplete="off" clearable
                required></v-text-field>
              <label class="add-text">Display Name</label><span style="color: red;"> *</span>
              <v-text-field class="text" v-model="display_name" outlined dense autocomplete="off" clearable
                required></v-text-field>
              <label class="add-text">Book Classification</label><span style="color: red;"> *</span>
              <v-autocomplete class="text" v-model="selectedbc" outlined :items="bclist" item-text="name" item-value="id"
                dense required></v-autocomplete>

              <center> <v-btn color="success" @click="addtitle">Add</v-btn></center>
            </v-form>
          </v-col>
          <v-col class="import-box">
            <v-row justify="end" class="my-2">
              <div><a href="https://library-public.s3.ap-south-1.amazonaws.com/sample_csv/titleimpoertxlsx.xlsx">
                  <v-btn class="mb-2 button"> <v-icon left dark>mdi-cloud-download</v-icon>Export XLSX
                    Template</v-btn></a>
              </div>
            </v-row>
            <label class="label">IMPORT XLSX</label>

            <v-file-input dense v-model="file" :hint="file != null ? '' : 'Please Select File'" persistent-hint
              color="rgb(1, 127, 152)" label="Choose File" accept=".xlsx" prepend-icon="mdi-paperclip" outlined show-size>
            </v-file-input>
            <v-btn @click="importCsv" :loading="isLoading" class="button">
              Import Title
              <v-icon right>mdi-cloud-upload</v-icon>
            </v-btn>

          </v-col>
          <v-col cols="12" sm=2 md=2>
          </v-col>
        </v-row>



        <template>
          <div class="text-center">
            <v-dialog v-model="dialog" width="500">
              <v-card>
                <v-card-title class="grey lighten-2"> Edit Title </v-card-title>

                <v-card-text style="padding-bottom: 0px">
                  <div class="add-section">
                    <div style="text-align: center">
                      <v-col>
                        <v-form ref="form">
                          <label class="add-text">Add New Title</label><span style="color: red;"> *</span>
                          <v-text-field class="text" v-model="edittitle" outlined dense :rules="[rules.required]"
                            autocomplete="off" clearable required></v-text-field>
                          <label class="add-text">Display Name</label><span style="color: red;"> *</span>
                          <v-text-field class="text" v-model="editdisplay_name" outlined dense :rules="[rules.required]"
                            autocomplete="off" clearable required></v-text-field>
                          <label class="add-text">Book Classification</label><span style="color: red;"> *</span>
                          <v-autocomplete class="text" v-model="editbc" outlined :items="bclist" item-text="name"
                            item-value="id" dense :rules="[rules.required]" required></v-autocomplete>
                        </v-form>
                      </v-col>
                    </div>
                  </div>
                </v-card-text>

                <!-- <v-divider></v-divider> -->

                <div class="text-center">
                  <v-spacer></v-spacer>
                  <v-btn class="m-3" @click="dialog = false"> Close </v-btn>
                  <v-btn class="m-3 green white--text" @click="saveEdit()">Update</v-btn>
                </div>
              </v-card>
            </v-dialog>
          </div>
        </template>

        <template>
          <h5 v-if="failList !== null" class="mt-4" style="text-align: center; color: red;">Failed Record</h5>
          <v-simple-table v-if="failList !== null">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Excel row no</th>
                  <th>Error</th>
                  <th>Title</th>
                  <th>Display Name</th>
                  <th>Book Classification</th>

                </tr>
              </thead>
              <tbody>
                <tr v-for="item in failList" :key="item.name" style="color:red;">
                  <th>{{ item.rowno }} </th>
                  <th>{{ item.error }} </th>
                  <th>{{ item.row.title }} </th>
                  <th>{{ item.row.display_name }} </th>
                  <th>{{ item.row.bool_classification }} </th>

                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>

        <template>
          <h5 v-if="successList !== null" class="mt-4" style="text-align: center; color: green;">Success Record</h5>
          <v-simple-table v-if="successList !== null">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Excel row no</th>
                  <th>Error</th>
                  <th>Title</th>
                  <th>Display Name</th>
                  <th>Book Classification</th>

                </tr>
              </thead>
              <tbody>
                <tr v-for="item in successList" :key="item.name" style="color:green;">
                  <th>{{ item.rowno }} </th>
                  <th>{{ item.error }} </th>
                  <th>{{ item.row.title }} </th>
                  <th>{{ item.row.display_name }} </th>
                  <th>{{ item.row.bool_classification }} </th>

                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>

        <template v-if="titledata != null">
          <v-data-table :headers="headers" :items="titledata" :search="search" class="elevation-1 mt-3 ">
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line
                  hide-details></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item.edit="{ item }">
              <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
              <v-icon v-if="item" class="mr-2" @click="updatetitle(item)">mdi mdi-pencil-box</v-icon>
            </template>
            <template v-slot:item.delete="{ item }">
              <v-icon v-if="item" class="mr-2" @click="deletetitle(item.id)">
                mdi mdi-delete</v-icon>
            </template>

            <template v-slot:item.actions="{ item }">
              <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
              <v-switch v-model="item.isactive" @change="editItem(item)"></v-switch>
            </template>
          </v-data-table>
        </template>
      </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({
    overlay: false,
    init_loading: false,
    snackbar_msg: "",
    snackbar: false,
    selectedbc: "",
    color: "",
    org: null,
    dialog: false,
    search: "",
    title: null,
    display_name: null,
    edittitle: null,
    editdisplay_name: null,
    editbc: null,
    yearid: null,
    bclist: [],
    userType: null,
    edit_btn_load: false,
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Admin Master',
        disabled: false,
        href: '/admin-master',
      },
      {
        text: 'Title',
        disabled: true,
        href: '',
      },
    ],
    rules: {
      required: (value) => !!value || "Required.",
    },

    titledata: null,
    headers: [
      { text: "Sr no", value: "srno" },
      { text: "Title", value: "title" },
      { text: "Display", value: "display_name" },
      { text: "Book Classification", value: "book_classfication" },
      { text: "Edit", value: "edit" },
      { text: "Delete", value: "delete" },
      { text: "Toggle", value: "actions", sortable: false },
      //{ text: "Toggle", value: "actions", sortable: false },
    ],
    fileStatus: true,
    count: -1,
    failList: [],
    successList: [],
    totalbooksize: 0,
    isLoading: false,
    file: null,
    percent: 0,
  }),

  methods: {
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    fileuploading() {
      let formData = new FormData();
      this.fileStatus = true;
      formData.append("file", this.file);
      formData.append("count", this.count);
      axios
        .post("ImportData/importTitle", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {

          // this.failList = res.data.failList;
          // this.successList = res.data.successList;
          if (res.data.status == "200") {
            if (this.count != -1) {

              for (var i = 0; i < res.data.successList.length; i++) {
                this.successList.push(res.data.successList[i]);
              }

              for (var i = 0; i < res.data.failList.length; i++) {
                this.failList.push(res.data.failList[i]);
              }
            }

            this.count = res.data.count;
            this.totalbooksize = res.data.totalbooksize;
            this.percent = (this.count / this.totalbooksize) * 100;
            if (res.data.isdone) {
              this.count = -1;
              this.isLoading = false;
              this.showSnackbar("#4caf50", "Books added successfully...");
            }
            else {
              this.fileuploading();
            }
            // this.onLoad();
          }

        })


    },
    importCsv() {
      this.isLoading = true;
      this.failList = [];
      this.successList = [];
      if (this.file !== null) {
        if (!(this.file.name.toLowerCase().includes(".xlsx") || this.file.name.toLowerCase().includes(".XLSX"))) {
          this.showSnackbar("red", "File must be in xlsx format Only.!");
        } else {
          this.fileuploading();
        }
      } else {
        this.fileStatus = false;
        this.showSnackbar("#b71c1c", "Please select file");
      }
    },
    editItem(item) {
      console.log(item);
      axios
        .post("/admin/titleIsactive", item)
        .then(() => {
          // this.sec_mode_list = res.data.user_list;
          this.showSnackbar("#4CAF50", "Title updated successfully...");
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          console.log(error);
        })
        .finally(() => {
          this.edit_btn_load = false;
          axios;
        });
    },

    updatetitle(item) {
      this.dialog = true;
      this.titleid = item.id;
      this.edittitle = item.title;
      this.editdisplay_name = item.display_name;
      this.editbc = item.bc_id;
    },

    saveEdit() {
      if (this.edittitle) {
        this.edittitle = this.edittitle.trim();
      }
      if (this.editdisplay_name) {
        this.editdisplay_name = this.editdisplay_name.trim();
      }

      const data = {
        titleid: this.titleid,
        edittitle: this.edittitle,
        editdisplay_name: this.editdisplay_name,
        book_classification: this.editbc,
      };

      if (this.edittitle && this.editdisplay_name) {
        axios
          .post("/admin/edittitle", data)
          .then((res) => {
            if (res.data.status == "success") {
              this.showSnackbar("#4CAF50", "Title updated successfully...");
              this.edittitle = "";
              this.editdisplay_name = "";

              this.dialog = false;
              this.onload();
            } else {
              this.showSnackbar("#b71c1c", res.data.status);
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
            console.log(error);
          })
          .finally(() => { });
      } else {
        this.showSnackbar("#b71c1c", "Please enter Academic Year ");
        this.$refs.form.validate();
      }
    },
    deletetitle(item) {
      const data = {
        titleid: item,
      };
      axios
        .post("/admin/deletetitle", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.showSnackbar("#4CAF50", "title Deleted Successfully  !!");
            this.onload();
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          window.console.log(error);
        });
    },

    onload() {
      this.init_loading = true;
      axios.post("/admin/gettitledata").then((res) => {
        this.init_loading = false;
        if (res.data.msg == "200") {
          this.titledata = res.data.titledata;
          this.bclist = res.data.bc_list;
        } else {
          this.init_loading = false;
        }
      });
    },

    addtitle() {
      if (this.title == "" || this.title == null) {
        this.showSnackbar("red", "Please Enter Title");
        return;
      }

      if (this.display_name == "" || this.display_name == null) {
        this.showSnackbar("red", "Please Enter Display Name");
        return;
      }

      if (this.selectedbc == "" || this.selectedbc == null) {
        this.showSnackbar("red", "Please Select Book Classification");
        return;
      }

      const data = {
        title: this.title,
        display_name: this.display_name,
        book_classification: this.selectedbc,
      };

      if (this.title && this.display_name) {
        axios
          .post("/admin/savetitle", data)
          .then((res) => {
            if (res.data.status == "success") {
              this.showSnackbar("#4CAF50", "Title Added successfully...");
              this.title = "";
              this.display_name = "";
              this.selectedbc = "";
              this.onload();
            } else {
              this.showSnackbar("#b71c1c", res.data.status);
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
            console.log(error);
          })
          .finally(() => { });
      } else {
        this.showSnackbar("#b71c1c", "Please Enter Year ");
        this.$refs.form.validate();
      }
    },
  },
  mounted() {
    this.onload();
  },
};
</script>
<style scoped>
.heading {
  background-color: #3f51b5;
  padding: 0.3rem;
  color: white;
}

.v-icon {
  cursor: pointer;
}

.text>>>.v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}
</style>
